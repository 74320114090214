import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AlertController, IonicModule, PopoverController } from '@ionic/angular';
import { Editor } from '@tiptap/core';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import StarterKit from '@tiptap/starter-kit';

import { CustomEmoji } from 'src/app/utils/tip-tap-extensions/custom-emoji';

import { EmojiPickerComponent } from '../emoji-picker/emoji-picker.component';

@Component({
  standalone: true,
  imports: [CommonModule, IonicModule],
  selector: 'app-slack-text-editor',
  templateUrl: './slack-text-editor.component.html',
  styleUrls: ['./slack-text-editor.component.css'],
})
export class SlackTextEditorComponent implements OnInit {
  @Input() content: string;
  @Input() isRichText = false;
  @Input() placeholder: string;
  @Input() hideStrikethrough: boolean;
  @Input() hideCodeblock: boolean;
  @Input() hideCode: boolean;
  @Output() contentDidChange = new EventEmitter();
  @Output() didBlur = new EventEmitter();
  @Output() didFocus = new EventEmitter();
  @ViewChild('editorWrapper', { static: true }) editorWrapper;

  private editor: Editor;

  constructor(
    private popoverCtrl: PopoverController,
    private alertCtrl: AlertController,
  ) {}

  emitChange() {
    this.contentDidChange.next(this.editor.getHTML());
  }

  ngOnInit() {
    this.editor = new Editor({
      element: this.editorWrapper.nativeElement,
      extensions: [
        StarterKit.configure({
          horizontalRule: false,
          heading: false,
          orderedList: this.isRichText
            ? {
                HTMLAttributes: {
                  class: 'slack-rich-text-list',
                },
              }
            : false,
          bulletList: this.isRichText
            ? {
                HTMLAttributes: {
                  class: 'slack-rich-text-list',
                },
              }
            : false,
        }),
        CustomEmoji.configure({
          inline: true,
        }),
        Link.configure({
          linkOnPaste: true,
        }),
        Placeholder.configure({
          placeholder: this.placeholder,
        }),
      ],
      content: this.content,
      injectCSS: true,
      onUpdate: () => {
        this.emitChange();
      },
      onBlur: () => {
        this.didBlur.emit();
      },
      onFocus: () => {
        this.didFocus.emit();
      },
    });
  }

  clearFormatting() {
    this.editor.chain().focus().clearNodes().unsetAllMarks().run();
    this.emitChange();
  }

  toggleBold() {
    this.editor.chain().focus().toggleBold().run();
    this.emitChange();
  }

  toggleItalic() {
    this.editor.chain().focus().toggleItalic().run();
    this.emitChange();
  }

  toggleBulletList() {
    this.editor.chain().focus().toggleBulletList().run();
    this.emitChange();
  }

  toggleOrderedList() {
    this.editor.chain().focus().toggleOrderedList().run();
    this.emitChange();
  }

  toggleStrikethrough() {
    this.editor.chain().focus().toggleStrike().run();
    this.emitChange();
  }

  toggleBlockquote() {
    this.editor.chain().focus().toggleBlockquote().run();
    this.emitChange();
  }

  toggleCodeBlock() {
    this.editor.chain().focus().toggleCodeBlock().run();
    this.emitChange();
  }

  toggleInlineCode() {
    this.editor.chain().focus().toggleCode().run();
    this.emitChange();
  }

  async insertEmoji() {
    const popover = await this.popoverCtrl.create({
      component: EmojiPickerComponent,
      componentProps: {
        callback: (emoji: any) => {
          popover.dismiss();

          if (emoji.native) {
            this.editor.commands.insertContent(emoji.native, {
              updateSelection: true,
              parseOptions: {
                preserveWhitespace: 'full',
              },
            });
          } else if (emoji.src) {
            this.editor.commands.insertCustomEmoji({
              name: emoji.name,
              src: emoji.src,
            });
          }

          this.emitChange();
        },
      },
      showBackdrop: false,
      cssClass: 'emoji-picker-popover',
      event,
    });

    popover.present();
  }

  async addLink() {
    const alert = await this.alertCtrl.create({
      header: 'Enter a link',
      inputs: [
        {
          name: 'link',
          placeholder: 'https://',
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Add',
          handler: (data) => {
            this.editor.chain().focus().setLink({ href: data.link }).run();
            this.emitChange();
          },
        },
      ],
    });

    alert.present();
  }
}
