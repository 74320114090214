<div>
  <div class="slack-message" style="margin: 10px 0" #editorWrapper></div>
  <div class="toolbar">
    <ion-button fill="clear" class="btn-small btn-formatting" color="dark" (click)="insertEmoji()" title="Emoji"
      ><ion-icon name="happy-outline"></ion-icon
    ></ion-button>
    <ion-button fill="clear" class="btn-small btn-formatting" color="dark" (click)="toggleBold()" title="Bold"
      ><ion-icon name="custom-bold"></ion-icon
    ></ion-button>
    <ion-button fill="clear" class="btn-small btn-formatting" color="dark" (click)="toggleItalic()" title="Italic"
      ><ion-icon name="custom-italic"></ion-icon
    ></ion-button>
    <ion-button
      fill="clear"
      class="btn-small btn-formatting"
      color="dark"
      (click)="toggleStrikethrough()"
      title="Strikethrough"
      [hidden]="hideStrikethrough"
      ><ion-icon name="custom-strikethrough"></ion-icon
    ></ion-button>
    <ion-button
      *ngIf="isRichText"
      fill="clear"
      class="btn-small btn-formatting"
      color="dark"
      (click)="toggleBulletList()"
      title="Bulleted list"
      ><ion-icon name="custom-unordered-list"></ion-icon
    ></ion-button>
    <ion-button
      *ngIf="isRichText"
      fill="clear"
      class="btn-small btn-formatting"
      color="dark"
      (click)="toggleOrderedList()"
      title="Ordered list"
      ><ion-icon name="custom-ordered-list"></ion-icon
    ></ion-button>
    <ion-button
      fill="clear"
      class="btn-small btn-formatting"
      color="dark"
      (click)="toggleBlockquote()"
      title="Block quote"
      ><ion-icon name="custom-blockquote"></ion-icon
    ></ion-button>
    <ion-button
      fill="clear"
      class="btn-small btn-formatting"
      color="dark"
      (click)="toggleInlineCode()"
      title="Code"
      [hidden]="hideCode"
      ><ion-icon name="custom-code-view"></ion-icon
    ></ion-button>
    <ion-button
      fill="clear"
      class="btn-small btn-formatting"
      color="dark"
      (click)="toggleCodeBlock()"
      title="Code block"
      [hidden]="hideCodeblock"
      ><ion-icon name="custom-code-block"></ion-icon
    ></ion-button>
    <ion-button fill="clear" class="btn-small btn-formatting" color="dark" (click)="addLink()" title="Add link"
      ><ion-icon name="custom-link"></ion-icon
    ></ion-button>
    <ion-button
      fill="clear"
      class="btn-small btn-formatting"
      color="dark"
      (click)="clearFormatting()"
      title="Clear format"
      ><ion-icon name="custom-format-clear"></ion-icon
    ></ion-button>
  </div>
</div>
